import styled from "styled-components";
import media from "@styles/media";

const HomeHeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
  position: relative;
  min-height: 100vh;
  height: 100%;
  ${media.toTabletP`
    margin: 0;
    justify-content: flex-start;
  `}
`;

export default HomeHeaderInner;
