import styled from "styled-components";
import media from "@styles/media";

const HomeHeader = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
  margin: 0 auto;
  ${media.toLaptopSmall`
    min-height: 100vh;
    height: auto;
  `}
`;

export default HomeHeader;
