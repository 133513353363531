import styled from "styled-components";
import media from "@styles/media";

const HomeHeaderLaptop = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 10%;
  right: 12%;
  width: 800px;
  ${media.desktop`
    right: 15%;
  `} 
  ${media.toLaptop`
    width: 650px;
  `} 
  ${media.toLaptopMedium`
    width: 600px;
  `} 
  ${media.toLaptopSmall`
    right: 12%;
    width: 500px;
  `} 
  ${media.toTablet`
    right: 17%;
    top: 20%;
  `} 
  ${media.toTablet`
    width: 400px;
  `} 
  ${media.toTabletP`
    max-width: 50vw;
    width: 100%;
    top: 6%;
    right: 20%;
  `} 
  ${media.toPhone`
    max-width: 50vw;
    width: 100%;
    top: 6%;
    right: 22%;
  `} 
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  img {
    height: 800px;
    width: 100%;
    max-width: 800px;
    max-height: 80vh;
  }
`;

export default HomeHeaderLaptop;
